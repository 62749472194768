import React, { useState} from 'react';
import {  graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomepageNewsSidebar from "../components/homepageNewsSidebar"
import { layoutStyles } from '../style/layoutStyles';
import Image from "../components/image"
import SectionLinks from "../components/sectionLinks";
import { sidebarStyle } from '../style/sideBarStyle';

var moment = require('moment');

export default ({ data }) => {
  const post = data.wpgraphql.port
  const news = data.wpgraphql.newsAll
  var newsArticles = [];
  var relatedPages=[];
  var featuredImage =[];
  const [fetchUrl, setFetchUrl] = useState("")
  const urlString = `/results?q=${fetchUrl}`
  var keywords = [];
  if (post.keywords.nodes != null)
  {
    post.keywords.nodes.forEach(node => { keywords.push(node.name);});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href=urlString;
  }

  if (post.featuredImage != null)
  {
    featuredImage.push({"url":post.featuredImage.node.mediaItemUrl,"title":post.featuredImage.node.altText, "link": post.AllPostTypeFields.bannerlink});

  }
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage1 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage1 != null)
    relatedPages.push({ "id": 0, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage1.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage1.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage1.target,"imagetitle": post.AllPostTypeFields.contentImage.contentImage1.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage1.mediaItemUrl});
    else
    relatedPages.push({ "id": 0, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage1.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage1.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage1.target,"imagetitle": "", "imageurl" : ""});
  }
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage2 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage2 != null)
  relatedPages.push({ "id": 1, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage2.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage2.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage2.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage2.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage2.mediaItemUrl});
  else
  relatedPages.push({ "id": 1, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage2.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage2.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage2.target,"imagetitle": "", "imageurl" : ""});
  }
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage3 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage3 != null)
  relatedPages.push({ "id": 2, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage3.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage3.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage3.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage3.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage3.mediaItemUrl});
    else
    relatedPages.push({ "id": 2, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage3.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage3.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage3.target,"imagetitle": "", "imageurl" : ""});
}
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage4 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage4 != null)
    relatedPages.push({ "id": 3, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage4.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage4.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage4.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage4.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage4.mediaItemUrl});
    else
    relatedPages.push({ "id": 3, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage4.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage4.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage4.target,"imagetitle": "", "imageurl" : ""});
}
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage5 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage5 != null)
    relatedPages.push({ "id": 4, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage5.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage5.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage5.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage5.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage5.mediaItemUrl});
    else
    relatedPages.push({ "id": 4, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage5.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage5.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage5.target,"imagetitle": "", "imageurl" : ""});

}
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage6 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage6 != null)
    relatedPages.push({ "id": 5, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage6.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage6.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage6.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage6.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage6.mediaItemUrl});
    else
    relatedPages.push({ "id": 5, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage6.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage6.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage6.target,"imagetitle": "", "imageurl" : ""});
}
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage7 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage7 != null)
    relatedPages.push({ "id": 6, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage7.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage7.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage7.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage7.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage7.mediaItemUrl});
    else
    relatedPages.push({ "id": 6, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage7.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage7.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage7.target,"imagetitle": "", "imageurl" : ""});
}
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage8 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage8 != null)
    relatedPages.push({ "id": 7, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage8.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage8.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage8.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage8.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage8.mediaItemUrl});
    else
    relatedPages.push({ "id": 7, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage8.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage8.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage8.target,"imagetitle": "", "imageurl" : ""});
  }
  if (post.AllPostTypeFields.relatedSectionPages.relatedPage9 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage9 != null)
     relatedPages.push({ "id": 8, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage9.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage9.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage9.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage9.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage9.mediaItemUrl});
    else
    relatedPages.push({ "id": 8, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage9.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage9.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage9.target,"imagetitle": "", "imageurl" : ""});

  }

  if (post.AllPostTypeFields.relatedSectionPages.relatedPage10 != null)
  {
    if (post.AllPostTypeFields.contentImage.contentImage10 != null)
    relatedPages.push({ "id": 9, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage10.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage10.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage10.target, "imagetitle": post.AllPostTypeFields.contentImage.contentImage10.title, "imageurl" : post.AllPostTypeFields.contentImage.contentImage10.mediaItemUrl});
    else
    relatedPages.push({ "id": 9, "title": post.AllPostTypeFields.relatedSectionPages.relatedPage10.title, "url" : post.AllPostTypeFields.relatedSectionPages.relatedPage10.url, "target": post.AllPostTypeFields.relatedSectionPages.relatedPage10.target,"imagetitle": "", "imageurl" : ""});

  }
  news.nodes.forEach(( node ) => {
    if (node.Newsfields.newsDate!==null && node.Newsfields.newsDate!=="") {
      var datesort = moment(node.Newsfields.newsDate).format('MM/DD/YYYY');
      var dateshort = moment(node.Newsfields.newsDate).format('DD/MM/YYYY');
    var colorClass;
    switch(node.Newsfields.newsCategory) {
      case 'Jersey Coastguard':
        colorClass = "coastguard";
        break;
      case 'Jersey Marinas':
        colorClass = "marinas";
        break;
      case 'Jersey Airport':
        colorClass = "airport";
        break;
      default:
        colorClass = "port";
    }  
    if (colorClass !== "airport")
      newsArticles.push({ "id": node.id, "title": node.title, "uri" : node.uri, "newsCategory" : node.Newsfields.newsCategory, "newsDate" : dateshort, "colorClass": colorClass, "dateSort": datesort})
    else
      newsArticles.push({ "id": node.id, "title": node.title, "uri" : process.env.GATSBY_AIRPORT_URL + node.uri.replace("/news","news"), "newsCategory" : node.Newsfields.newsCategory, "newsDate" : dateshort, "colorClass": colorClass, "dateSort": datesort})
    }
  });
  newsArticles.sort(function(a, b) {
    a = new Date(a.dateSort);
    b = new Date(b.dateSort);
    return a>b ? -1 : a<b ? 1 : 0;
  });

  var NewsSide=newsArticles.slice(0, 15);

  return (
  <Layout>
    <SEO title="Ports of Jersey"  keywords={keywords} />
      <div css={layoutStyles} id="content">
        <div className="grid">
        <div className="content col-1-1">
            <div className="masthead">
                <div className="masthead col-1-1">
                <Image scr={featuredImage} Mode="port"/>                   
                </div>
            </div>
          </div>
            <div id="sectionPage" className="col-1-1">

              <div id="article" className="col-8-12">
                  <h1>{post.title}</h1>
                 <div dangerouslySetInnerHTML={{ __html: post.content }} />
                 <SectionLinks SectionLinks={relatedPages} Mode="port"  />

              </div>

              <div id="aside" className="col-4-12">
              <div css={sidebarStyle} id="sidebar">
        
        <div id="tools">
          <div id="search">
          <form onSubmit={handleSubmit} >
            <label htmlFor="searchBox"><h2 id="searchTitle">Search This Site</h2></label>
            <input
              name="searchBox"
              type="text"
              id="searchBox"
              className="search"
              style={{ height: "23px", marginRight:"3px"}}
              onChange={(event) => setFetchUrl(event.target.value)}
            />
            <input
              type="submit"
              className="searchSubmit"
              value=""
              alt="Search"
            />
            </form>
          </div>
          </div>
          </div>
                <HomepageNewsSidebar NewsArticles={NewsSide} />
              </div>
          </div>
        </div>
        <div className="whitespacer"></div>
      </div>
    </Layout>
  )
}
export const query = graphql`
query GET_PORTSWELCOME($id: ID!) {
    wpgraphql {
      port(id: $id) {
        title
        content(format: RENDERED)
        slug
        keywords {
          nodes {
            name
          }
        }
        featuredImage {
          node
          {
          mediaItemUrl
          altText
          }
        }
        AllPostTypeFields {
          bannerlink
          contentImage{
            contentImage1 {
              mediaItemUrl
              title
            }
            contentImage2 {
              mediaItemUrl
              title
            }
            contentImage3 {
              mediaItemUrl
              title
            }
            contentImage4 {
              mediaItemUrl
              title
            }
            contentImage5 {
              mediaItemUrl
              title
            }
            contentImage6 {
              mediaItemUrl
              title
            }
            contentImage7{
              mediaItemUrl
              title
            }
            contentImage8 {
              mediaItemUrl
              title
            }
            contentImage9 {
              mediaItemUrl
              title
            }
            contentImage10 {
              mediaItemUrl
              title
            }
  
          }
          relatedSectionPages {
            relatedPage1 {
            title
            url
            target
          }
          relatedPage2 {
            title
            url
            target
          }
          relatedPage3 {
            title
            url
            target
          }
          relatedPage4 {
            title
            url
            target
          }
          relatedPage5 {
            title
            url
            target
          }
          relatedPage6 {
            title
            url
            target
          }
          relatedPage7 {
            title
            url
            target
          }
          relatedPage8 {
            title
            url
            target
          }
          relatedPage9 {
            title
            url
            target
          }
          relatedPage10 {
            title
            url
            target
          }
        }
       
      }
      }
      newsAll(where: {orderby: {field: DATE, order: DESC}}, first: 50) {
        nodes {
          uri
          title
          id
          slug
          Newsfields {
            newsCategory
            newsDate
          }
        }
      }
    }
   
  }
  
`