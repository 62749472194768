import React from "react"
import { relatedLinkStyle } from '../style/relatedLinksStyle';

const HomepageNewsSidebar = (props) => {
  return (
    <React.Fragment>
      <div css={relatedLinkStyle}  id="related">
        <h2 className="ports"><a href="/news">Ports of Jersey News</a></h2>
        <ul className="post">
          {props.NewsArticles.map(link => <li key={link.id}><a href={link.uri}><strong>{link.newsDate}</strong> - <span dangerouslySetInnerHTML={{ __html: link.title }}></span></a></li>)}
        </ul>
      </div>
    </React.Fragment>
  )
}
export default HomepageNewsSidebar
